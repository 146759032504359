import React,{useState, useEffect} from 'react';
import Select from 'react-select';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// image
import axios from 'axios';
const Index = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const [fieldValue, setFieldValue]=useState();
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        // Extract values safely from the event
        const formData = new FormData(e.target);
        const currentPassword = formData.get("currentpassword");
        const newPassword = formData.get("password");
        const confirmPassword = formData.get("confirmpassword");
        const email = userDetails.email; // Assuming email is stored in userDetails
      
        // Basic validation
        if (!currentPassword || !newPassword || !confirmPassword) {
          toast.error('All fields are required!');
          return;
        }
        
        if (newPassword !== confirmPassword) {

          toast.error('Passwords do not match!');
          return;
        }
      
        try {
          setLoading(true); // Show loading state
      
          const response = await axios.post(`${baseUrl}changepassword/`, {
            email,
            currentpassword: currentPassword,
            password: newPassword,
            confirmpassword: confirmPassword,
          });
      
          toast.success('Password Change Successfully');
        } catch (error) 
        {
         console.log(error);
          alert(error.response?.data?.error || "Something went wrong. Please try again.");
          toast.error(error.response?.data?.error || "Something went wrong. Please try again.");
        } finally {
          setLoading(false); // Hide loading state
        }
      };
      


    
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                  <ToastContainer position="top-right"/>
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Change User Password </h6>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                        
                                     
                                        <div className="col-sm-10 m-b30">
                                            <label className="form-label">Current Password  </label>
                                            <input type="password" className="form-control" id="currentpassword" name="currentpassword" placeholder="current password" required />
                                        </div>
                                        <div className="col-sm-12 m-b30">
                                            <label className="form-label">New Password </label>
                                            <input type="password" className="form-control" id="password" name="password" placeholder="New Password" required />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Confirm Password </label>
                                            <input type="password" className="form-control" id="confirmpassword" name="confirmpassword" placeholder=" Confirm Password" required  />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-success">Change Password</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
              
                </div>
            </div>
        </>
    )
}
export default Index;