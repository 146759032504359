import React,{useState} from 'react';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import SessionIndex from '../../Reports/Views/Session/'
const CreateDepartment = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();

    const userDetails = JSON.parse(localStorage.getItem('userDetails'));

    function handleSubmit(e) 
    {
        e.preventDefault();
        const path ="savesession";
        const userData ={
                   
                    name : e.target.session.value,
                    userID:userDetails.userID,
                    createdBy:userDetails.email,
            }
        dispatches(saveAction(userData,path,navigate));
       // window.location.reload();
    }
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-6">
                 
                    </div>
                    <div className="col-xl-9 col-lg-6">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create School Session </h6>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                            
                                   
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Semester Name</label>
                                            <input type="text" className="form-control" name='session' id='session' placeholder="2000/2001" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add Session</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                    <SessionIndex/>
                </div>
            </div>
        </>
    )
}
export default CreateDepartment;