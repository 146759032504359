import React,{useState, useEffect,Fragment,useReducer} from 'react';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import  { fetchItemUser } from '../../Lists/Users';
import Select from 'react-select';
import  { fetchSchools} from '../../Lists/Schools';
import  { fetchApplicationsItems } from '../../Lists/Applications';
import  { fetchItemTrackingStage  } from '../../Lists/TrackingStage';
import PageTitle from "../../../../layouts/PageTitle";
import {
  Col,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from 'axios';



const examTopper = [
  {id:'111', color:'success', status:'Successful'},
];

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'contentModal'	:
			return { ...state, contentModal: !state.contentModal}
		case 'modalCentered'	:
			return { ...state, modalCentered: !state.modalCentered}
		case 'modalWithTooltip'	:
			return { ...state, modalWithTooltip: !state.modalWithTooltip}		
		case 'gridInsideModal'	:
			return { ...state, gridInsideModal: !state.gridInsideModal}
		case 'largeModal'	:
			return { ...state, largeModal: !state.largeModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default :	
			return state;
	}	
}

const Index = () => {
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [dataSchools, setDataSchools] =useState([]);
    const [userName, setUserName] =useState([]);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const userID = userDetails.userID;
    const [fieldValue, setFieldValue]=useState();
    const [checked, setChecked] = useState(examTopper);
    const [unchecked, setUnChecked] = useState(true);
    const [state ,dispatch] = useReducer(reducer, init);
    const [selectStatusValue, setselectStatusValue] = useState();
    const [names, setNames] =useState([]);
    const [ID, setID] =useState([]);
    const [transcriptID, setTranscriptID] =useState('');
    const [applicantIName, setApplicantName] =useState('');
    const [data, setData] =useState([]);
    const [trackingData, setTrackingData] =useState();
    const [studentId, setStudentId] =useState();
    const headers   = {'Content-Type': 'application/json', 'Authorization-Key': `${userDetails.apikey}`};
    const baseUrl = process.env.REACT_APP_BASE_URL;  
      const handleCheckedAll = (value)=> {
          let temp = checked.map((data) => {          
              return { ...data, inputchecked: value };   
          });
          setChecked(temp);
          setUnChecked(!unchecked);
      };


const selectStatusOnChange = (selectedOption) => 
{
  setFieldValue('status', selectedOption.value);
  setselectStatusValue(selectedOption);
};
      
    function handleSubmit(e) 
    {
        e.preventDefault();
        const path ="savetracking";
        const userData ={
                    transcriptId : transcriptID,
                    stageId: selectStatusValue.value,
                    studentId: studentId,
                    comment: e.target.comment.value,
                    userID:userDetails.userID,
                    createdBy:userDetails.email,
            }
        dispatches(saveAction(userData,path,navigate));
        dispatch({type:'largeModal'})
    }



   
useEffect(()=>{
    const fetchApplications = async () => {
        try 
        {
           const response = await fetchApplicationsItems();
           setData(response.data);
        } catch (error) {
          setError(error.message);
        }
      };
    fetchApplications()
},[]);

useEffect(()=>{
          listApplication()
},[])
      
const listApplication = async (transcriptID) => {
 // console.log(transcriptID);
    try {
      const response = await axios.get(`${baseUrl}savetracking/get_next_stage/${transcriptID}`);
      //console.log(response.data);
      if (response.data.statuscode === 200)
      {
        const nextStage = response.data.next_stage;
        const customHeadings = nextStage
          ? [
              {
                value: nextStage.stageId,
                label: nextStage.name
              }
            ]
          : []; // Fallback if next_stage is null or undefined
        
        setTrackingData(customHeadings);
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      if (error.response) {
        console.error('Server responded with an error:', error.response);
      } else if (error.request) {
        console.error('Network error occurred:', error.request);
      } else {
        console.error('Error:', error.message);
      }
    }
  };

  const handleEditClick = (item) => {
    dispatch({ type: "largeModal" });
    setApplicantName(item.applicantName);
    setTranscriptID(item.transcriptId);
    setStudentId(item.studentId);
    setID(item.id);
    listApplication(item.transcriptId)
  };



    return(
        <>

            <Fragment>
            {/* <PageTitle activeMenu="Information" motherMenu="School" /> */}
            <PageTitle activeMenu="Information" motherMenu="Transcript Tracking" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-6"></div>
                    <Col lg={12}>
                    <Card>
                        <Card.Header>
                        <Card.Title>Transcript Applications</Card.Title>
                        </Card.Header>
                        <Card.Body>
                        <Table responsive striped>
                            <thead>
                            <tr>
                                <th><strong>NO.</strong></th>
                                <th><strong>TranscriptId</strong></th>
                                <th><strong>StudentId</strong></th>
                                <th><strong>Name</strong></th>
                                <th><strong>Amount</strong></th>
                                <th><strong>Destination</strong></th>
                                <th><strong>Email</strong></th>
                                <th><strong>Phone</strong></th>
                                <th><strong>Country</strong></th>
                                <th><strong>TransactionID</strong></th>
                                <th><strong>CreatedDate</strong></th>
                                <th><strong>Action</strong></th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.transcriptId}</td>
                                <td>{item.studentId}</td>
                                <td>{item.applicantName}</td>
                                <td>{item.amount}</td>
                                <td>{item.destination}</td>
                                <td>{item.email}</td>
                                <td>{item.recipientPhone}</td>
                                <td>{item.country}</td>
                                <td>{item.transactionId}</td>
                                <td>{item.createdDate}</td>
                                <td>
                                    <div className="d-flex">
                                    <Link
                                        to="#"
                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                        onClick={() => handleEditClick(item)}
                                    >
                                        <i className="fas fa-pencil-alt"></i>
                                    </Link>
                                    </div>
                                </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        </Card.Body>
                    </Card>
                    </Col>
                </div>
           </div>

             {/* <!-- Modal Activated --> */}
		    <Modal className="fade" show={state.largeModal} onHide={()=>dispatch({type:'largeModal'})}>
                <form className="profile-form" onSubmit={handleSubmit}>
                                <Modal.Header>
                                    <Modal.Title>Transcript Stage for [{ applicantIName } of { transcriptID }]</Modal.Title>
                                    <Button variant="" className="btn-close" onClick={() => dispatch({type:'largeModal'})}></Button>
                                </Modal.Header>
                                <Modal.Body>
                                    
                            
                                    <div className="card-body">
                                        <div className="row">              
                                                <div className="col-sm-8 m-b30">
                                                        <label className="form-label">Processing Stage</label>
                                                        <Select options={trackingData} value={selectStatusValue}  onChange={selectStatusOnChange} className="custom-react-select" 
                                                        isSearchable={true} required
                                                        />
                                                </div>
                                        
                                            
                                        </div>
                                        <div className="row">            
                                                <div className="col-sm-12 m-b30">
                                                    <label className="form-label">Comment </label>
                                                    <input type="text" className="form-control" 
                                                    id="comment" 
                                                    name="comment" 
                                                    placeholder="Add Comments"  required/>
                                                </div>

                                        </div>
                                    </div>
                                    
                            
                                

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button  variant="danger light" onClick={() => dispatch({type:'largeModal'})}>Close</Button>
                                    <button className="btn btn-primary">Submit</button>
                                </Modal.Footer>
                </form>   
			</Modal>
 
           </Fragment>


       
            




        </>
    )
}
export default Index;