import React,{useState} from 'react';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import DegreeIndex from '../../Reports/Views/Degree/'
const CreateDepartment = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();

    const userDetails = JSON.parse(localStorage.getItem('userDetails'));

    function handleSubmit(e) 
    {
        e.preventDefault();
        const path ="savedegreeclass";
        const userData ={
                   
                    name : e.target.degreeclass.value,
                    userID:userDetails.userID,
                    createdBy:userDetails.email,
            }
        dispatches(saveAction(userData,path,navigate));
        //window.location.reload();
    }
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-6">
                 
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create Class of Degree </h6>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                            
                                   
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Name</label>
                                            <input type="text" className="form-control" name='degreeclass' id='degreeclass' placeholder="Distinction" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add </button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                    <DegreeIndex />
                </div>
            </div>
        </>
    )
}
export default CreateDepartment;