import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// image
import logo from "../../images/logo/logo-full.png";
const ForgotPassword = ({ history }) => {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
 
  const onSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    try {
      const response = await axios.post(`${baseUrl}resetpassword/`, {email});

      console.log(response);
      if (response.status === 200) {
          navigate("/dashboard");
      } else {
          console.error("Password reset failed:", response.data);
      }
  } catch (error) {
      console.error("Error during password reset:", error.message);
  }
  };
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/login">
                        <img src={logo} alt="" width={220} height={70} />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Reset Password</h4>
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className="form-group">
                        <label className="">
                          <strong>New Password</strong>
                        </label>
                        <input
                          type="password" name="password" id="password" placeholder="Password" required
                          className="form-control"
                          defaultValue=""
                        />
                        <label className="">
                          <strong>Confirm Password</strong>
                        </label>
                        <input
                          type="password" name="confirmpassword" id="confirmpassword" placeholder="Password" required
                          className="form-control"
                          defaultValue=""
                        />
                      </div>
                      <br />  <br />
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          SUBMIT
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
