import React,{useState, useEffect,Fragment,useReducer} from 'react';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import  { fetchItemUser } from '../../Lists/Users';
import Select from 'react-select';
import  { fetchSchools} from '../../Lists/Schools';

import PageTitle from "../../../../layouts/PageTitle";
import {
  Col,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";
import { Link } from "react-router-dom";

const examTopper = [
  {id:'111', color:'success', status:'Successful'},
];

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'contentModal'	:
			return { ...state, contentModal: !state.contentModal}
		case 'modalCentered'	:
			return { ...state, modalCentered: !state.modalCentered}
		case 'modalWithTooltip'	:
			return { ...state, modalWithTooltip: !state.modalWithTooltip}		
		case 'gridInsideModal'	:
			return { ...state, gridInsideModal: !state.gridInsideModal}
		case 'largeModal'	:
			return { ...state, largeModal: !state.largeModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default :	
			return state;
	}	
}

const dataStatus =
[
   { value:'True',   label:'Activate'},
//    { value:'False', label:'Suspend'},
]
const Index = () => {
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [dataSchools, setDataSchools] =useState([]);
    const [userName, setUserName] =useState([]);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const userID = userDetails.userID;
    const [fieldValue, setFieldValue]=useState();
    const [checked, setChecked] = useState(examTopper);
    const [unchecked, setUnChecked] = useState(true);
    const [state ,dispatch] = useReducer(reducer, init);
    const [selectStatusValue, setselectStatusValue] = useState();
    const [names, setNames] =useState([]);
    const [ID, setID] =useState([]);

      const handleCheckedAll = (value)=> {
          let temp = checked.map((data) => {          
              return { ...data, inputchecked: value };   
          });
          setChecked(temp);
          setUnChecked(!unchecked);
      };


const selectStatusOnChange = (selectedOption) => 
{
  setFieldValue('status', selectedOption.value);
  setselectStatusValue(selectedOption);
};
      
    function handleSubmit(e) 
    {
        e.preventDefault();
        const path ="adminactivateschools";
        const userData ={
                    id : ID,
                    status: selectStatusValue.value,
                    userID:userDetails.userID,
                    createdBy:userDetails.email,
            }
        dispatches(saveAction(userData,path,navigate));
        dispatch({type:'basicModal'})
    }



   
useEffect(()=>{
        const fetchSchoolData = async () => {
            try 
            {
               const response = await fetchSchools();
               setDataSchools(response.data);
            } catch (error) {
              setError(error.message);
            }
          };
          fetchSchoolData()
},[]);
        

    return(
        <>

            <Fragment>
            {/* <PageTitle activeMenu="Information" motherMenu="School" /> */}
            <PageTitle activeMenu="Information" motherMenu="School List" />
            <div className="container-fluid">
                  <div className="row">
                    <div className="col-xl-1 col-lg-6">
                    </div>
                    <Col lg={12}>
                    <Card>
                    <Card.Header>
                        <Card.Title>Schools</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive striped security='1'> 
                        <thead>
                            <tr>
                                    <th><strong>NO.</strong></th>
                                    <th><strong>Name</strong></th>
                                    <th><strong>Email</strong></th>
                                    <th><strong>UserID</strong></th>
                                    <th><strong>Status</strong></th>
                                    <th><strong>Date</strong></th>
                                    <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataSchools.map((item, index)=>(
                            <tr key={index}>
                               
                                
                                <td>{++index}</td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.userID}</td>
                                <td>{item.status ===true ? 'Activated' : 'Suspended'}</td>
                                <td>{item.date_joined}</td>
                             
                                <td>
                                <div className="d-flex">
                                    <Link
                                    to={"#"}
                                    onClick={() => dispatch({type:'basicModal'}, setUserName(item.userID), setNames(item.name), setID(item.id)) }
                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                    >
                                    <i className="fas fa-pencil-alt"></i>
                                    </Link>
                                   
                                </div>
                                </td>
                            </tr>
                            ))}
                            
                        </tbody>
                        </Table>
                    </Card.Body>
                    </Card>
                    </Col>     
                  </div>
             </div>

             {/* <!-- Modal Activated --> */}
		    <Modal className="fade" show={state.basicModal} onHide={()=>dispatch({type:'basicModal'})}>
                <form className="profile-form" onSubmit={handleSubmit}>
                                <Modal.Header>
                                    <Modal.Title>Activated Account [{ names }]</Modal.Title>
                                    <Button variant="" className="btn-close" onClick={() => dispatch({type:'basicModal'})}></Button>
                                </Modal.Header>
                                <Modal.Body>
                                    
                            
                                    <div className="card-body">
                                        <div className="row">              
                                                <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Activated/Suspend</label>
                                                        <Select options={dataStatus} value={selectStatusValue}  onChange={selectStatusOnChange} className="custom-react-select" 
                                                        isSearchable={true}
                                                        />
                                                </div>
                                        
                                            
                                        </div>
                                    </div>
                                    
                            
                                

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button  variant="danger light" onClick={() => dispatch({type:'basicModal'})}>Close</Button>
                                    <button className="btn btn-primary">Submit</button>
                                </Modal.Footer>
                </form>   
			</Modal>
 
           </Fragment>


       
            




        </>
    )
}
export default Index;