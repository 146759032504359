import React,{useState, useEffect, useMemo} from 'react';
import Select from 'react-select';
import {useNavigate } from "react-router-dom";
import {useDispatch,useSelector } from 'react-redux';
import axios from 'axios';
import { saveAction, getServiceAction } from '../../../../../../src/store/actions/AuthActions'
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  { fetchItemGrade } from '../../Lists/Grade';
import  { fetchItemsSession  } from '../../Lists/Session';
import Results from '../../Reports/Views/Results'

const sidebarLink = [
    {to:'/addGrade', title:'Add Grade'},
    {to:'/addScores', title:'Add Scores'},
  ];



  const options3 = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
    { value: 'F', label: 'F' }
]

const Index = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [activeLink ,setActiveLink] = useState(0);
    const [results ,setResults] = useState(0);
    const [data ,setData] = useState(0);
    const [userinfo, setUserInfo] =useState([]);
    const [dataGrade, setDataGrade] =useState([]);
    const [dataPoint, setDataPoint] =useState([]);
    const [dataSession, setDataSession] =useState([]);
    const [dataLevel, setDataLevel] =useState([]);
    const [department, setDepartment] =useState([]);
    const [dataSemester, setDataSemester] =useState([]);
    const [selectPointValue, setselectPointValue] = useState();
    const [datacourse, setDataCourse] =useState([]);
    const [selectCourscodeValue, setselectCoursecodeValue] = useState();
    const [selectSemesterValue, setselectSemesterValue] = useState();
    const [selectSessionValue, setselectSessionValue] = useState();
    const [selectLevelValue, setselectLevelValue] = useState();
    const [fieldValue, setFieldValue]=useState();
    const [selectGradeValue, setselectGradeValue] = useState();
    const [selectDepartmentValue,setselectDepartmentValue]=useState();
    const [inputValue, setInputValue] = useState('');
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const userID =userDetails.userID;
    const [inputSession, setInputSession] = useState(null);
    const [inputValues, setInputValues] = useState([]);
    const saveState = useSelector(state => state.save);
    const headers   = {'Content-Type': 'multipart/form-data', 'Authorization-Key': `${userDetails.apikey}`};
    function handleSubmit(e) 
    {
      e.preventDefault();
      const path ="savescourses";
      const userData ={
                   semester:selectSemesterValue.value,
                   sessionID:selectSessionValue.value,
                   courscode :selectCourscodeValue.value,
                   level: selectLevelValue.value,
                   point : e.target.point.value,
                   score:e.target.score.value,
                   name : e.target.faculty.value,
                   userID:userDetails.userID,
                   createdBy:userDetails.email,
         }
      dispatches(saveAction(userData,path,navigate));
    }
    const handleChange = (e) => {
      setInputValue(e.target.value);
    };
  
    const onchangeMatricNo=(e)=>
    {
      toast.success("Please wait while loading your academic session.....");
      const timer = setTimeout(() => { }, 5000);
      e.preventDefault();
      const userData={ matricno: inputValue,  
                       userID:   userDetails.userID,
                       createdBy:userDetails.email };
      axios.post(`${baseUrl}getstudentsessions/`,userData,{headers}).then(response => 
       {       
        // console.log(response); 
         setInputSession('');
          if(response.data.statuscode === 200)
          {
               const customHeadings = response.data.data.map(item=>({
              "value": item.sessionID,
              "label": item.session       
              }))
            setInputSession(customHeadings);    
          }
          else
          { 
            //console.log(response)
            toast.error(response.data.error);           
            return () => clearTimeout(timer);
          }
         }) .catch((error) => {
         if (error.response) {
             //console.log(error.response);
         } else if (error.request) {
             //console.log("network error");
         } else {
             //console.log(error);
         }
         });
    }
    const selectSessionOnChange = (selectedOption) => 
    {
      setFieldValue('session', selectedOption.value);
      setselectSessionValue(selectedOption);
      let session = selectedOption.value;
      loadsemester(session)
      loadLevel(session)
    }
    const selectSemesterOnChange = (selectedOption) => 
    {
      setFieldValue('semester', selectedOption.value);
      setselectSemesterValue(selectedOption);
    }
    const selectCoursecodeOnChange = (selectedOption) => 
    {
      setFieldValue('courscode', selectedOption.value);
      setselectCoursecodeValue(selectedOption);
    }
    const selectLevelOnChange = (selectedOption) => 
    {
      setFieldValue('level', selectedOption.value);
      setselectLevelValue(selectedOption);
    }
    const selectDepartmentOnChange = (selectedOption) => 
     {
      setFieldValue('department', selectedOption.value);
      setselectDepartmentValue(selectedOption);
     }
     const selectGradeOnChange = (selectedOption) => 
     {
       setFieldValue('grade', selectedOption.value);
       setselectGradeValue(selectedOption);
     };
     const selectPointOnChange = (selectedOption) => 
     {
       setFieldValue('point', selectedOption.value);
       setselectPointValue(selectedOption);
      
     };

     const loadCourses =()=>{
        axios.get(`${baseUrl}savescourses/${userDetails.userID}`,{headers}).then(response => 
          {
            //console.log(response);
             const customHeadings = response.data.data.map(item=>({
                         "value": item.coursecode,
                         "label": item.coursetitle + ' '+item.coursecode       
                      }))
                      setDataCourse(customHeadings);
              }) .catch((error) => {
              if (error.response) {
                  console.log(error.response);
                  console.log("server responded");
              } else if (error.request) {
                  console.log("network error");
              } else {
                  console.log(error);
              }
              });
      }
    const loadDepartment =()=>{
        axios.get(`${baseUrl}savedepartment/${userDetails.userID}`,{headers}).then(response => 
          {
            //console.log(response);
             const customHeadings = response.data.data.map(item=>({
                         "value": item.id,
                         "label": item.name        
                      }))
                      setDepartment(customHeadings);
              }) .catch((error) => {
              if (error.response) {
                  console.log(error.response);
                  console.log("server responded");
              } else if (error.request) {
                  console.log("network error");
              } else {
                  console.log(error);
              }
              });
      }
    
      const loadLevel =(session)=>{
        axios.get(`${baseUrl}savelevel/${session}/${userDetails.userID}`,{headers}).then(response => 
          {
            
           
             const customHeadings = response.data.data.map(item=>({
                         "value": item.name,
                         "label": item.name        
                      }))
                      setDataLevel(customHeadings);
              }) .catch((error) => {
              if (error.response) {
                  console.log(error.response);
                  console.log("server responded");
              } else if (error.request) {
                  console.log("network error");
              } else {
                  console.log(error);
              }
              });
      }
      const loadsemester =(session)=>{
        axios.get(`${baseUrl}savesemester/${session}/${userDetails.userID}`, {headers}).then(response => 
            {
             
            
               const customHeadings = response.data.data.map(item=>({
                           "value": item.id,
                           "label": item.name        
                        }))
                        setDataSemester(customHeadings);
                }) .catch((error) => {
                if (error.response) {
                    console.log(error.response);
                    console.log("server responded");
                } else if (error.request) {
                    console.log("network error");
                } else {
                    console.log(error);
                }
                });
    
    
      }
   
      useEffect(()=>{
        loadCourses()
        loadDepartment();
      },[])
      useEffect(()=>{
        const fetchSessionData = async (userID) => {
            try 
            {
            
               const response = await fetchItemsSession(userID);
               //console.log(response);
               const customHeadings = response.data.map(item=>({
                "value": item.id,
                "label":item.name      
             }))
             setDataSession(customHeadings);
            } catch (error) {
              setError(error.message);
            }
          };
          fetchSessionData(userID)
     },[userID])
        

  function clearSessionData()
  {
    localStorage.removeItem('data');
    localStorage.removeItem('matricno');
    localStorage.removeItem('semester');
    localStorage.removeItem('session');
    localStorage.removeItem('level');
  }

      function handleSearch(e) 
      {
          e.preventDefault();      
          clearSessionData();
          const userData ={                   
                      matricno : e.target.matricno.value,
                      semester:selectSemesterValue.value,
                      session:selectSessionValue.value,
                      level: selectLevelValue.value,
                      userID:userDetails.userID,
                      createdBy:userDetails.email,
              }
            
             
              axios.post(`${baseUrl}getstudent/`,userData,{headers}).then(response => 
                {          
                  if(response.data.statuscode===0)
                  {
                    setResults(response.data.data);
                    localStorage.setItem('data',JSON.stringify(response.data.data[0]));
                    const datas =localStorage.getItem('data');
                    setData(JSON.parse(datas));
                    localStorage.setItem('matricno', inputValue);
                    localStorage.setItem('session', selectSessionValue.value);
                    localStorage.setItem('level', selectLevelValue.value);
                    localStorage.setItem('semester', selectSemesterValue.value);
                    
                    
                  }
                  else
                  {
                    toast.error('Matricno Not Found');
                    const timer = setTimeout(() => 5000);
                    ///window.location.reload(true);
                    return () => clearTimeout(timer);              
                  }
                 

                    }) .catch((error) => {
                    if (error.response) {
                        console.log(error.response);
                        console.log("server responded");
                    } else if (error.request) {
                        console.log("network error");
                    } else {
                        console.log(error);
                    }
                    });
          
      }

        //console.log(results);
      useEffect(()=>{
        const fetchGradeData = async (userID) => {
            try 
            {
              const response = await fetchItemGrade(userID);
              const customHeadings = response.data.map(item=>({
                  "value": item.grade,
                  "label": item.grade        
               }))
               setDataGrade(customHeadings);
            } catch (error) {
              setError(error.message);
            }
          };
          fetchGradeData(userID)
        },[])
        useEffect(()=>{
          const fetchPointData = async (userID) => {
              try 
              {
                const response = await fetchItemGrade(userID);
                const customHeadings = response.data.map(item=>({
                    "value": item.point,
                    "label": item.point        
                 }))
                 setDataPoint(customHeadings);
              } catch (error) {
                setError(error.message);
              }
            };
            fetchPointData(userID)
          },[])
      
      // function handleAddSubmit(e)
      // {
      //   e.preventDefault();
      //   const path ="studentresults";
      //   const datas =localStorage.getItem('data');
      //   var deptJsonObject = JSON.parse(datas);
      //   const userData ={
      //               matricno:data.matricno,
      //               coursecode : selectCourscodeValue.value,
      //               semesterID : selectSemesterValue.value,
      //               levelID :selectLevelValue.value,
      //               sessionID:selectSessionValue.value,
      //               departmentID:deptJsonObject.departmentID,
      //               score:e.target.score.value,
      //               point: selectPointValue.value,
      //               grade: selectGradeValue.value,
      //               userID:userDetails.userID,
      //               createdBy:userDetails.email,
      //       }
      //   dispatches(saveAction(userData,path,navigate));
      // }

      const handleInputChange = (e, index) => {
        const newValues = [...inputValues];
        newValues[index] = e.target.value;
        setInputValues(newValues);
        
      };  
      function handleAddSubmit(e) {
        e.preventDefault();
        const path = "studentresults";
          
        const userData = {
          matricno: inputValue,
          coursecode: results,
          semesterID: selectSemesterValue.value,
          levelID: selectLevelValue.value,
          sessionID: selectSessionValue.value,
          departmentcode: results[0].departmentcode,
          score: inputValues,
          gpa:e.target.gpa.value,
          cgpa : e.target.cgpa.value,
          userID: userDetails.userID,
          createdBy: userDetails.email,
        };
        dispatches(saveAction(userData, path, navigate));
        setResults('')
      }

      //console.log(localStorage.getItem('matricno'))
    return(
        <>      
         <ToastContainer position="top-center"></ToastContainer>
            <div className="container-fluid">
                <div className="row">

                    {/* MENU  STARTS*/}
                     <div className="col-xl-2 col-lg-4">
                       <div className="demo-right ">
                          <div className="demo-right-inner dlab-scroll " id="right-sidebar">
                            <h4 className="title">MENU</h4>
                            <ul className="navbar-nav" id="menu-bar">
                                {sidebarLink.map((item, ind)=>(
                                  <li key={ind}                        
                                  >
                                    <Link to={item.to}  smooth={true}
                                      className={`scroll ${ind === activeLink ? 'active' :  ''} `}
                                      activeClass="active"
                                      spy={true}
                                      onClick={()=>setActiveLink(ind)}
                                    > 
                                      {item.title}
                                    </Link>
                                  </li>
                                ))}
                            
                             </ul>	
                          </div>
                        </div>
                      </div>
                    {/* MENU  ENDS*/}

                      {/* BODY 1  STARTS*/}
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Student Score Entry Page </h6>
                            </div>
                            <form  onSubmit={handleSearch}>
                                <div className="card-body">
                                   <div className="row">                      
                                    <div className="col-sm-3 m-b30">
                                            <label className="form-label">Matricno </label>
                                            <input type="text" className="form-control" 
                                            onChange={handleChange} 
                                            onBlur={onchangeMatricNo} 
                                            id="matricno" 
                                            name="matricno" 
                                            placeholder="matricno"  required/>
                                        </div>
                                        <div className="col-sm-3 m-b30">
                                            <label className="form-label">Session </label>
                                          {inputSession &&  <Select options={inputSession}  className="custom-react-select" 
                                            placeholder="Session"
                                            value={selectSessionValue} onChange={selectSessionOnChange}
                                            isSearchable={true}
                                        /> 
                                         }
                                        </div>
                                        <div className="col-sm-3 m-b30">
                                            <label className="form-label">Semester </label>
                                            <Select options={dataSemester}  className="custom-react-select" 
                                            placeholder="Semester"
                                            value={selectSemesterValue}
                                            onChange={selectSemesterOnChange}
                                            isSearchable={true}
                                        />
                                        </div>
                                        <div className="col-sm-2 m-b30">
                                            <label className="form-label">Level </label>
                                            <Select options={dataLevel}  className="custom-react-select" 
                                            placeholder="Level"
                                            value={selectLevelValue} onChange={selectLevelOnChange}
                                            isSearchable={true}
                                        />
                                        </div>
                                         

                                       
                                      
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-success">Submit</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* BODY 1 END*/}

                     {/* BODY 2 END*/}

                       {/* MENU  STARTS*/}
                         <div className="col-xl-2 col-lg-4">
                          <div className="demo-right ">
                          <div className="demo-right-inner dlab-scroll " id="right-sidebar">
                            
                          </div>
                        </div>
                          </div>
                       {/* MENU  ENDS*/}


                        {results &&<div className="col-xl-9 col-lg-8">
                            <div className="card profile-card card-bx m-b30">
                                <div className="card-header">
                                    <h6 className="title">Enter Scores </h6>
                                    <label style={{ color:"red" }}>MatricNo: { localStorage.getItem('matricno')} </label>
                                    <label style={{ color:"red" }}>Level: { localStorage.getItem('level')} </label>
                                </div>
                                <form  onSubmit={handleAddSubmit}>
                                    <div className="card-body">
                                        <div className="row">
                            
                                            <div className="col-xl-10 col-lg-4">
                                            
                                            </div>
                                        {results.map((item, index)=>(
                                          <>
                                        
                                            <div className="col-sm-3 m-b10">
                                                <label className="form-label" key={index} > <label> {++index}.  </label>  {item.coursecode} </label>
                                              </div> 
                                            <div className="col-sm-2 m-b10">
                                                <input type="text" 
                                                className="form-control" 
                                                placeholder="Score"  
                                                id={`score${item}`}  // Use a unique identifier in the ID
                                                name={`score${item}`} // Use a unique identifier in the name
                                                onChange={(e) => handleInputChange(e, index)}
                                                required/>
                                            </div>


                                          
                                            </>
                                        ))}
                                        </div>
                                        <br/>
                                        <hr/>
                                        <div className="row">
                                            <div className="col-sm-2 m-b30"><input type="text" className="form-control" 
                                                  
                                                    id="gpa" 
                                                    name="gpa" 
                                                    placeholder="GPA"  required/>
                                            </div>
                                            <div className="col-sm-2 m-b30">
                                                  <input type="text" className="form-control" 
                                                    
                                                      id="cgpa" 
                                                      name="cgpa" 
                                                      placeholder="CGPA"  required/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button className="btn btn-success">Add Score</button>
                                      
                                    </div>
                                </form>
                            </div>

                        
                          </div>
                        }
                 
                         {results.length==0 &&
                            <Results matricno={localStorage.getItem('matricno')} 
                              semester={localStorage.getItem('semester')}
                              session ={localStorage.getItem('session')}
                              level   ={localStorage.getItem('level')}
                              /> 
                          }                      
                    </div>

                
            </div>
        </>
    )
}
export default Index;