import React, { Fragment, useState, useReducer, useEffect} from "react";
import PageTitle from "../../../../layouts/PageTitle";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { IMAGES } from '../../../../constant/theme';
import axios from "axios";
import {loadingToggleAction, saveAction } from '../../../../../../src/store/actions/AuthActions'
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import TrackingStageIndex from '../../Reports/Views/TrackingStage';
const examTopper = [
  {id:'111', color:'success', status:'Successful'},
//   {id:'112', color:'danger', status:'Canceled'},
//   {id:'113', color:'warning', status:'Pending'},
];

const init = false


const Index = () => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const [checked, setChecked] = useState(examTopper);
    const [unchecked, setUnChecked] = useState(true);

    
    const handleChecked = (id)=> {
          let temp = checked.map((data) => {
              if (id === data.id) {
                  return { ...data, inputchecked: !data.inputchecked };
              }
              return data;
          });
          setChecked(temp);
      };
      const handleCheckedAll = (value)=> {
          let temp = checked.map((data) => {          
              return { ...data, inputchecked: value };   
          });
          setChecked(temp);
          setUnChecked(!unchecked);
      };
  
    
  
    function handleSubmit(e) 
    {
      e.preventDefault();
      const path ="savetrackingstage";
      const userData ={
                   
                   name : e.target.name.value,
                   stageId : e.target.stageId.value,
                   description : e.target.description.value,
                   userID:userDetails.userID,
                   createdBy:userDetails.email,
         }
        
      dispatches(saveAction(userData,path,navigate));
      //window.location.reload();
    }




    
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-6 col-lg-4">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create Transcript Stage </h6>
                            </div>
                            <form onSubmit={handleSubmit}> 
                                <div className="card-body">
                                    <div className="row">
                       
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Stage Name</label>
                                            <input type="text" className="form-control" name="name" placeholder="Name of the Stage" required />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Stage Number</label>
                                            <input type="text" className="form-control" name="stageId" id="stageId" placeholder="Stage Number" required />
                                        </div>
                                        <div className="col-sm-12 m-b30">
                                            <label className="form-label">Description </label>
                                            <input type="text" className="form-control" id="description" name="description" placeholder="Description of the stage" required />
                                        </div>
                                   </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add Stage</button>
                                  
                                </div>
                                </div>                              
                            </form>

                        </div>
                    </div>

                     <TrackingStageIndex />
                    
                </div>


    </div>
    
        </>
    )
}
export default Index;