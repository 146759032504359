import React from 'react';
import { useNavigate } from "react-router-dom";


import {
    formatError,formatSuccess,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,uploader,uploaders, saveServices, getServices,getformatSuccess,saveService
} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';
export const UPLOAD_CONFIRMED_ACTION = '[upload action] confirmed upload';
export const SUCCESSFUL_CONFIRMED_ACTION = '[saved action] confirmed Saved';

export function signupAction(email, password,schoolName, navigate) {
    return (dispatch) => {

       
        signUp(email, password,schoolName)
        .then((response) => {
          
            saveTokenInLocalStorage(response.data);
            runLogoutTimer(
                dispatch,
                response.data.expiresIn * 1000,
                //history,
            );
            dispatch(confirmedSignupAction(response.data));
            navigate('/confirm_signup');
			//history.push('/dashboard');
        })
        .catch((error) => {
            const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}
export function uploadAction(file, path, userID, createdBy, navigate) {

    return (dispatch) => {
        uploader(file, path,userID,createdBy)
        .then((response) => {
            dispatch(confirmedUploadAction(response.data));
            console.log(response.data);
            const Message = formatSuccess(response.data);
            dispatch(savedSuccessfulAction(Message));
            //navigate('/impFaculty');
        })
        .catch((error) => {
            const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}



export function uploadActions(userData, navigate) {

    return (dispatch) => {
        uploaders(userData)
        .then((response) => {
            dispatch(confirmedUploadAction(response.data));
            const Message = formatSuccess(response.data);
            dispatch(savedSuccessfulAction(Message));
            //navigate('/impFaculty');
        })
        .catch((error) => {
            const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}


export function saveAction(userData, path, navigate) {

    return (dispatch) => {
         saveServices(userData, path)
        .then((response) => {
            dispatch(confirmedUploadAction(response.data));
            const Message = formatSuccess(response.data);
            dispatch(savedSuccessfulAction(Message));
        })
        .catch((error) => {
            console.log(error.response);
            const Message = formatSuccess(error.response.data);
            dispatch(savedSuccessfulAction(Message));
        });
    };
}


export function saveActions(userData, path, navigate) {

    return (dispatch) => {
         saveService(userData, path)
        .then((response) => {
            dispatch(confirmedUploadAction(response.data));
            const Message = formatSuccess(response.data);
            dispatch(savedSuccessfulAction(Message));
        })
        .catch((error) => {
            console.log(error);
            const Message = formatSuccess(error.response.data);
            dispatch(savedSuccessfulAction(Message));
        });
    };
}



export function getServiceAction(path) {
    return (dispatch) => {
         getServices(path)
        .then((response) => {
            //console.log(response);
            dispatch(confirmedUploadAction(response.data));
            const Message = getformatSuccess(response.data);
            dispatch(savedSuccessfulAction(Message));
        })
        .catch((error) => {
            const Message = formatSuccess(error.response.data);
            dispatch(savedSuccessfulAction(Message));
        });
    };
}





export function Logout(navigate) {
	localStorage.removeItem('userDetails');
    navigate('/login');	    
	return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, navigate) {
    return  (sendRequest) => {
         login(email, password)
            .then((response) => { 
                console.log(response)
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    sendRequest,
                    response.data.expiresIn * 1000,
                    navigate,
                );
             
                sendRequest(loginConfirmedAction(response.data));			              
				navigate('/dashboard');                
            })
            .catch((error) => {
                //console.log(error);
                const errorMessage = formatError(error.response.data);
                sendRequest(loginFailedAction(errorMessage));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
   
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
   
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}


export function confirmedUploadAction(payload) {
   
    return {
        type: UPLOAD_CONFIRMED_ACTION,
        payload,
    };
}
export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function savedSuccessfulAction(message) {
    return {
        type: SUCCESSFUL_CONFIRMED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export const navtoggle = () => {
    // alert(1);
    return {        
      type: 'NAVTOGGLE',
    };
  };
